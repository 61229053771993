/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { IHeader, translate } from '../../libs/utils';
import Pagination from '../../components/table/Pagination';
import { Box } from '@mui/material';
import TableBody from '../../components/members/TableBody';
import { useAppDispatch } from '../../redux';
import { useSelector } from 'react-redux';
import {
  membersSelector,
  deleteStatusSelector,
} from '../../redux/members/selectors';
import { deleteMember, getMembers } from '../../redux/members/action';
import { IData } from '../../redux/members/types';
import TableButton from '../../components/table/TableButton';
import Table from '../../components/table/Table';
import { calculateTotalPage } from '../../libs/utils/calculateTotalPage';
import SearchInput from '../../components/members/SearchInput';
import DeletePopup from '../../components/popup/DeletePopup';
import { useNavigate } from 'react-router-dom';
import ImportCSVFilePopup from '../../components/popup/csv/ImportCSVFilePopup';
import ImportCSVSuccessPopup from '../../components/popup/csv/ImportCSVSuccessPopup';
import ImportErrorPopup from '../../components/popup/csv/ImportErrorPopup';
import { clearDeleteStatus, clearState } from '../../redux/members';
import { HTTP_STATUS } from '../../configs';

const headers: IHeader[] = [
  { id: 'kanji_name', label: translate('member.list.table.kanji_name') },
  { id: ' furigana_name', label: translate('member.list.table.furigana_name') },
  {
    id: 'dental_registration_number',
    label: translate('member.list.table.regist_number'),
  },
  { id: 'email', label: translate('member.list.table.email_address') },
  {
    id: 'user_type',
    label: translate('member.list.table.membership_type'),
  },
  {
    id: 'area_name',
    label: translate('member.list.table.area'),
  },
  { id: 'birth_date', label: translate('member.list.table.date_of_birth') },
  { id: 'edit_action', label: translate('member.list.table.edit_action') },
  { id: 'delete_action', label: translate('member.list.table.delete_action') },
];

const MembersScreen: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isShowDeletePopup, setIsShowDeletePopup] = useState<boolean>(false);
  const [isShowImportCSVPopup, setIsShowImportCSVPopup] =
    useState<boolean>(false);
  const [isShowImportSuccessPopup, setIsShowImportSuccessPopup] =
    useState<boolean>(false);
  const [isShowImportErrorPopup, setIsShowImportErrorPopup] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isDeletedMember, setIsDeletedMember] = useState<boolean>(false);

  const navigate = useNavigate();

  const [selectedMemberId, setSelectedMemberId] = useState<number>(0);

  const dispatch = useAppDispatch();
  const data: IData = useSelector(membersSelector);
  const deleteStatus: number = useSelector(deleteStatusSelector);

  const redirectToCreateMemberScreen = () => navigate('/members/create');

  const handleClickEditMember = (memberId: number) =>
    navigate(`/members/${memberId}`);

  const handleOnChangePage = (page: number) => {
    setCurrentPage(page);
    dispatch(getMembers({ page: page, search: searchValue }));
  };

  const handleClickDeleteMember = (memberId: number) => {
    setSelectedMemberId(memberId);
    setIsShowDeletePopup(true);
  };

  const handleConfirmDeleteMember = () => {
    setIsDeletedMember(true);
    setIsShowDeletePopup(false);
    dispatch(deleteMember(selectedMemberId));
  };

  const handleCancelDeleteMember = () => {
    setIsShowDeletePopup(false);
  };

  const handleCloseDeletePopup = () => {
    setIsShowDeletePopup(false);
  };

  const handleOnInputSearchValue = (searchValue: string) => {
    setSearchValue(searchValue);
    dispatch(getMembers({ page: currentPage, search: searchValue }));
  };

  const handleFilterMember = () => {
    dispatch(getMembers({ page: currentPage, search: searchValue }));
  };

  const handleButtonClick = () => {
    setIsShowImportCSVPopup(true);
  };

  const handleShowImportSuccessPopup = (isShowPopup: boolean) => {
    setIsShowImportSuccessPopup(isShowPopup);
  };

  const handleShowImportErrorPopup = (isShowPopup: boolean) => {
    setIsShowImportErrorPopup(isShowPopup);
  };

  useEffect(() => {
    dispatch(getMembers({ page: currentPage, search: searchValue }));
  }, [currentPage, dispatch, searchValue]);

  useEffect(() => {
    if (deleteStatus === HTTP_STATUS.OK) {
      setIsDeletedMember(false);
      dispatch(clearState());
      dispatch(clearDeleteStatus());
      dispatch(getMembers({ page: currentPage, search: searchValue }));
    }
  }, [currentPage, deleteStatus, dispatch, isDeletedMember, searchValue]);

  return (
    <Layout title={translate('member.list.title')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          bgcolor: 'background.paper',
          py: 2,
          borderRadius: 1,
          mb: '5px',
          mt: '-35px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SearchInput
            searchValue={searchValue}
            onChange={handleOnInputSearchValue}
            onClick={handleFilterMember}
          />
          <Box>
            <TableButton
              label={translate('member.list.button.import_csv')}
              onClick={handleButtonClick}
            />
          </Box>
          <span style={{ marginLeft: '9px', marginRight: '9px' }}></span>
          <TableButton
            label={translate('member.list.button.add')}
            onClick={redirectToCreateMemberScreen}
          />
        </Box>
      </Box>
      <Table headers={headers}>
        <TableBody
          data={data.members}
          onClickEdit={handleClickEditMember}
          onClickDelete={handleClickDeleteMember}
        />
      </Table>
      {data?.members?.length > 0 && (
        <Pagination
          total={calculateTotalPage(data.total)}
          page={data.current_page}
          textTotal={`${translate('table.total.label')} ${data.total}${translate('member.list.table.paginate.label')}`}
          onChangePage={handleOnChangePage}
        />
      )}
      {isShowDeletePopup && (
        <DeletePopup
          onConfirm={handleConfirmDeleteMember}
          onCancel={handleCancelDeleteMember}
          onClose={handleCloseDeletePopup}
          open={isShowDeletePopup}
        />
      )}

      {isShowImportCSVPopup && (
        <ImportCSVFilePopup
          onClose={() => setIsShowImportCSVPopup(false)}
          onOpenSuccessPopup={handleShowImportSuccessPopup}
          onOpenErrorPopup={handleShowImportErrorPopup}
          open={isShowImportCSVPopup}
        />
      )}

      {isShowImportSuccessPopup && (
        <ImportCSVSuccessPopup
          onClose={() => setIsShowImportSuccessPopup(false)}
          open={isShowImportSuccessPopup}
        />
      )}

      {isShowImportErrorPopup && (
        <ImportErrorPopup
          onClose={() => setIsShowImportErrorPopup(false)}
          open={isShowImportErrorPopup}
        />
      )}
    </Layout>
  );
};
export default MembersScreen;
