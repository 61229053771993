/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSdk } from '../../libs/apis';
import {
  IAdminUserState,
  ICreateAdminUserState,
  IDetailAdminUserState,
} from './types';
import {
  IAdminUserLocal,
  ICreateAdminUser,
} from '../../libs/apis/admin-users/types';

export const getAdminUsers = createAsyncThunk(
  '/admin/users',
  async (adminUser: IAdminUserLocal) => {
    const data: IAdminUserState =
      await apiSdk.adminUserApis.getAdminUsers(adminUser);
    return data;
  },
);

export const getDetailAdminUser = createAsyncThunk(
  '/admin/user',
  async (adminId: number) => {
    const adminUser: IDetailAdminUserState =
      await apiSdk.adminUserApis.getDetailAdminUser(adminId);
    return adminUser;
  },
);

export const createAdminUser = createAsyncThunk(
  '/admin/user/create',
  async (adminUser: ICreateAdminUser, { rejectWithValue }) => {
    try {
      const adminUserResponse: ICreateAdminUserState =
        await apiSdk.adminUserApis.createAdminUser(adminUser);
      return adminUserResponse;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateAdminUser = createAsyncThunk(
  '/admin/user/update',
  async (
    data: { adminUserData: ICreateAdminUser; id: string },
    { rejectWithValue },
  ) => {
    const { adminUserData, id } = data;
    try {
      const data: ICreateAdminUserState =
        await apiSdk.adminUserApis.updateAdminUser(adminUserData, id);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
