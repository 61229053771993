import { FC, ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { WHITE } from '../../../themes/colors';
import Title from '../Title';
import Content from '../Content';

interface Props {
  title: string;
  isOpen: boolean;
  contents: string[];
  children: ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles({
  container: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '430px',
    height: '400px',
    backgroundColor: WHITE,
    borderWidth: '0px',
    borderRadius: '10px',
    boxShadow: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    height: '250px',
    overflowY: 'auto', // 🔹 Thêm thanh cuộn khi nội dung quá dài
    paddingRight: '8px', // 🔹 Tránh che khuất nội dung bởi thanh cuộn
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 1,
    paddingTop: '8px',
  },
});

const LayoutImportError: FC<Props> = ({
  title,
  isOpen,
  contents,
  onClose,
  children,
}) => {
  const styles = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box className={styles.container}>
        <Stack direction="column" gap={2} flex={1}>
          <Title title={title} />
          <Box className={styles.contentContainer}>
            {contents.map((message: string, index: number) => (
              <Content key={index} content={message} />
            ))}
          </Box>
          <Box className={styles.buttonContainer}>{children}</Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default LayoutImportError;
