export const API_BASE_URL =
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_API_URL || 'http://localhost:8000';

export const SAMPLE_MEMBER_CSV_FILE = '/api/admin/download-member-csv-template';
export const DOWNLOAD_APK_API = '/api/download/apk';

export enum HTTP_STATUS {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  UNPROCESSABLE_CONTENT = 422,
}

export enum TOKEN_KEYS {
  ACCESS_TOKEN = '_token',
  REFRESH_TOKEN = '_refresh_token',
  EXPIRES_IN = '_expires_in',
}

export enum UserType {
  REGULAR = 1,
  ASSOCIATE = 2,
  ADMIN_STAFF = 3,
  ALL_MEMBER = 4,
}

export const supportedLanguage = [
  { language: 'japan', code: 'JP' },
  { language: 'english', code: 'en' },
];

export enum SeminarType {
  LOCAL_EVENT = 0,
  ONLINE_EVENT = 1,
  ALL = 2,
}

export enum AdditionalDay {
  DATE = 'date',
  START_TIME = 'start_time',
  END_TIME = 'end_time',
}

export enum MemberShipType {
  REGULAR = 0,
  ASSOCIATE = 1,
  ADMIN_STAFF = 2,
}

export const USER = '_hpda_user';

export type MappedData = {
  kanji_name: string;
  furigana_name: string;
  email: string;
  birth_date: string;
  area_id: string;
  dental_registration_number: string;
  user_type: string;
};

export const columnMapping: Record<string, keyof MappedData> = {
  漢字名: 'kanji_name',
  フリガナ: 'furigana_name',
  メール: 'email',
  生年月日: 'birth_date',
  地区号: 'area_id',
  歯科医師会番号: 'dental_registration_number',
  会員種別: 'user_type',
};

export const userTypeMapping: Record<string, string> = {
  正会員: '0',
  準会員: '1',
  事務員: '2',
};

export enum AdminUserRole {
  ADMINISTRATOR = 1,
  USER = 2,
  OFFICER = 3,
}
