/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useRef, ChangeEvent, useEffect, useCallback } from 'react';
import { translate } from '../../../libs/utils';
import { GREEN, WHITE } from '../../../themes/colors';
import Layout from './Layout';
import { Box, Button, Typography } from '@mui/material';
import Papa from 'papaparse';
import { columnMapping, MappedData, userTypeMapping } from '../../../configs';
import { BUTTON_HEIGHT } from '../../../themes/fonts';
import CancelButton from '../CancelButton';
import { useAppDispatch } from '../../../redux';
import { importMembers } from '../../../redux/import-members/action';
import { useSelector } from 'react-redux';
import {
  isImportedSelector,
  successSelector,
} from '../../../redux/import-members/selectors';
import { getAreas } from '../../../redux/areas/action';
import { areasSelector } from '../../../redux/areas/selectors';
import { clearState } from '../../../redux/import-members';

interface Props {
  onClose: () => void;
  onOpenSuccessPopup: (isShow: boolean) => void;
  onOpenErrorPopup: (isShow: boolean) => void;
  open: boolean;
}

const ImportCSVFilePopup: FC<Props> = ({
  onClose,
  onOpenSuccessPopup,
  onOpenErrorPopup,
  open,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useAppDispatch();
  const isImportSuccess: boolean = useSelector(successSelector);
  const isImportedMembers: boolean = useSelector(isImportedSelector);
  const areas = useSelector(areasSelector);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleOpenSuccessPopup = useCallback(() => {
    onOpenSuccessPopup(true);
  }, [onOpenSuccessPopup]);

  const handleOpenErrorPopup = useCallback(() => {
    onOpenErrorPopup(true);
  }, [onOpenErrorPopup]);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleSendDataToServer = (data: MappedData[]) => {
    dispatch(importMembers(data));
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!file.name.endsWith('.csv') || file.type !== 'text/csv') {
      console.log('有効なCSVファイル（.csv）を選択してください。');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const buffer = e.target?.result as ArrayBuffer;
      // Convert ArrayBuffer to text with Shift-JIS encoding
      const decoder = new TextDecoder('shift-jis');
      const text = decoder.decode(buffer);

      Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          console.log('result.data:', result.data);
          const parsedData = result.data.map((row: any) => {
            const mappedRow: Partial<MappedData> = {};
            for (const [csvColumn, mappedKey] of Object.entries(
              columnMapping,
            )) {
              const value = row[csvColumn];
              if (!value) {
                console.warn(`Missing value for column ${csvColumn}`);
                continue;
              }

              if (csvColumn === '会員種別') {
                for (const [typeValue, code] of Object.entries(
                  userTypeMapping,
                )) {
                  if (typeValue === value.trim()) {
                    mappedRow[mappedKey] = code.toString();
                  }
                }
              } else if (csvColumn === '地区号') {
                for (const [areaValue, code] of Object.entries(areas)) {
                  if (code.area_name === value.trim()) {
                    mappedRow[mappedKey] = code.area_id.toString();
                  }
                }
              } else {
                console.log('row[csvColumn]: ', value, csvColumn, row);
                mappedRow[mappedKey] = value.trim();
              }
            }
            return mappedRow as MappedData;
          });
          const data = parsedData as MappedData[];
          handleSendDataToServer(data);
        },
        error: (error: { message: string }) => {
          console.log(error.message);
        },
      });
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (!areas.length) {
      dispatch(getAreas());
    }

    if (isImportedMembers && isImportSuccess) {
      dispatch(clearState());
      handleClose();
      handleOpenSuccessPopup();
    } else if (isImportedMembers && !isImportSuccess) {
      handleClose();
      handleOpenErrorPopup();
    }
  }, [
    areas,
    dispatch,
    handleClose,
    handleOpenErrorPopup,
    handleOpenSuccessPopup,
    isImportSuccess,
    isImportedMembers,
  ]);

  const SelectFileButton = () => {
    return (
      <Button
        onClick={handleButtonClick}
        sx={{
          width: '110px',
          bgcolor: GREEN,
          borderRadius: '5px',
          height: BUTTON_HEIGHT,
          fontSize: '8px',
        }}
        variant="contained"
      >
        <Typography sx={{ fontSize: '10px', color: WHITE }}>
          {translate('member.list.button.import_csv')}
        </Typography>
      </Button>
    );
  };

  return (
    <Layout
      title={translate('popup.importCSV.select_file.title')}
      isOpen={open}
      onClose={handleClose}
    >
      <Box mr={1}>
        {SelectFileButton()}
        <input
          type="file"
          accept=".csv"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
      </Box>
      <CancelButton
        label={translate('popup.importCSV.button_no')}
        width={'100px'}
        onClick={handleClose}
      />
    </Layout>
  );
};

export default ImportCSVFilePopup;
