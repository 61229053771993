import { AxiosInstance } from 'axios';
import {
  IAdminUserState,
  ICreateAdminUserState,
  IDetailAdminUserState,
} from '../../../redux/admin-users/types';
import { IAdminUserLocal, ICreateAdminUser } from './types';

export class AdminUserApi {
  constructor(private axiosInstance: AxiosInstance) {}

  async getAdminUsers(adminUser: IAdminUserLocal): Promise<IAdminUserState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/admin-users?page=${adminUser.page}`,
    );
    return data;
  }

  async getDetailAdminUser(adminId: number): Promise<IDetailAdminUserState> {
    const { data } = await this.axiosInstance.get(
      `/api/admin/admin-users/${adminId}`,
    );
    return data;
  }

  async createAdminUser(
    adminUser: ICreateAdminUser,
  ): Promise<ICreateAdminUserState> {
    const { data } = await this.axiosInstance.post(
      '/api/admin/admin-users',
      adminUser,
    );
    return data;
  }

  async updateAdminUser(
    adminUser: ICreateAdminUser,
    id: string,
  ): Promise<ICreateAdminUserState> {
    const { data } = await this.axiosInstance.put(
      `/api/admin/admin-users/${id}`,
      {
        name: adminUser.name,
        email: adminUser.email,
        role: adminUser.role,
        dental_registration_number: adminUser.dental_registration_number,
        birth_date: adminUser.birth_date,
      },
    );
    return data;
  }
}
