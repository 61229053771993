import { FC } from 'react';
import ConfirmButton from '../ConfirmButton';
import { translate } from '../../../libs/utils';
import { GREEN } from '../../../themes/colors';
import Layout from '../Layout';
import { useAppDispatch } from '../../../redux';
import { clearState } from '../../../redux/import-members';

interface Props {
  onClose: () => void;
  open: boolean;
}

const ImportCSVSuccessPopup: FC<Props> = ({ onClose, open }: Props) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(clearState());
    onClose();
  };

  return (
    <Layout
      title={translate('popup.importCSV.success.title')}
      content={translate('popup.importCSV.success.content')}
      isOpen={open}
      onClose={handleClose}
    >
      <ConfirmButton
        label={translate('popup.create.button')}
        width={'195px'}
        bgColor={GREEN}
        onClick={handleClose}
      />
    </Layout>
  );
};

export default ImportCSVSuccessPopup;
