import { MemberShipType } from '../../configs';
import { translate } from './translate';

export * from './moneyFormat';
export * from './translate';

export const round = (value: number, fractionDigits = 2): number => {
  return Number(value.toFixed(fractionDigits));
};

export const toPercent = (value: number, fractionDigits = 2): number => {
  return round(value * 100, fractionDigits);
};

export const stringToNumber = (str: string): number => {
  return +str.replace(/\./g, '');
};

export const emailRegex =
  /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

export const passwordRegex = /^[a-zA-Z0-9.?/-]{6,24}$/;

export interface IHeader {
  id: string;
  label: string;
}

export interface IUserType {
  type: string;
  value: number;
}

export interface IAdminRole {
  type: string;
  value: number;
}

export const renderUserType = (genderCode: number) => {
  if (genderCode === MemberShipType.REGULAR)
    return translate('member.list.table.membership_type.regular');
  if (genderCode === MemberShipType.ASSOCIATE)
    return translate('member.list.table.membership_type.associate');
  if (genderCode === MemberShipType.ADMIN_STAFF)
    return translate('member.list.table.membership_type.admin_staff');
};
