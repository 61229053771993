/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import { importMembers } from './action';
import { IImportMembersState } from './types';

export const initialState: any = {
  message: '',
  success: false,
  isImported: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: null,
};

const importMemberSlice = createGenericSlice({
  name: 'importMembers',
  initialState,
  reducers: {
    clearState(state) {
      state.message = '';
      state.success = false;
      state.isImported = false;
      state.status = HTTP_STATUS.UNAUTHORIZED;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importMembers.rejected, (state, action: any) => {
      state.status = action.payload.status;
      state.success = action.payload.success;
      state.message = action.payload.message;
      state.isImported = true;

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.errors = action.payload.errors;
      }
    });

    builder.addCase(
      importMembers.fulfilled,
      (state, action: PayloadAction<IImportMembersState>) => {
        state.success = action.payload.success;
        state.status = action.payload.status;
        state.isImported = true;
      },
    );
  },
});

export { importMembers };
export const { clearState } = importMemberSlice.actions;
export default importMemberSlice.reducer;
