/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTTP_STATUS } from '../../configs';
import { createGenericSlice } from '../../libs/utils/createGenericSlice';
import {
  getAdminUsers,
  getDetailAdminUser,
  createAdminUser,
  updateAdminUser,
} from './action';
import {
  IAdminUserState,
  ICreateAdminUserState,
  IDetailAdminUserState,
} from './types';
import { PayloadAction } from '@reduxjs/toolkit';

const initialDetailAdminUserState = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: {
    admin_user_id: 0,
    name: '',
    email: '',
    role: 0,
    birth_date: '',
    dental_registration_number: '',
  },
};

export const initialState: any = {
  message: '',
  success: false,
  status: HTTP_STATUS.UNAUTHORIZED,
  data: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    from: 0,
    to: 0,
    items: [],
  },
  detailAdminUser: initialDetailAdminUserState,
};

const adminUserSlice = createGenericSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    clearDetailAdminUser: (state) => {
      state.data = {};
      state.success = false;
      state.status = HTTP_STATUS.UNAUTHORIZED;
      state.detailAdminUser = initialDetailAdminUserState;
    },
    clearState: (state) => {
      state.data = {
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,
        from: 0,
        to: 0,
        items: [],
      };
      state.message = '';
      state.success = false;
      state.status = HTTP_STATUS.UNAUTHORIZED;
      state.errors = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminUsers.rejected, (state, action: any) => {
      state.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.message = action.payload.message;
        state.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.status = action.payload.status;
      }
    });

    builder.addCase(
      getAdminUsers.fulfilled,
      (state, action: PayloadAction<IAdminUserState>) => {
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.status = action.payload.status;
      },
    );

    builder.addCase(getDetailAdminUser.rejected, (state, action: any) => {
      state.detailAdminUser.status = action.payload.status;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.detailAdminUser.message = action.payload.message;
        state.detailAdminUser.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.detailAdminUser.status = action.payload.status;
      }
    });

    builder.addCase(
      getDetailAdminUser.fulfilled,
      (state, action: PayloadAction<IDetailAdminUserState>) => {
        state.detailAdminUser.success = action.payload.success;
        state.detailAdminUser.data = action.payload.data;
        state.detailAdminUser.status = action.payload.status;
      },
    );

    builder.addCase(createAdminUser.rejected, (state, action: any) => {
      state.status = action.payload.status;
      state.errors = action.payload.errors;
      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.message = action.payload.message;
        state.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.status = action.payload.status;
      }
    });

    builder.addCase(
      createAdminUser.fulfilled,
      (state, action: PayloadAction<ICreateAdminUserState>) => {
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.status = action.payload.status;
      },
    );

    builder.addCase(updateAdminUser.rejected, (state, action: any) => {
      state.status = action.payload.status;
      state.errors = action.payload.errors;

      if (action.payload.status === HTTP_STATUS.BAD_REQUEST) {
        state.message = action.payload.message;
        state.success = action.payload.success;
      }

      if (action.payload.status === HTTP_STATUS.UNPROCESSABLE_CONTENT) {
        state.status = action.payload.status;
      }
    });

    builder.addCase(
      updateAdminUser.fulfilled,
      (state, action: PayloadAction<ICreateAdminUserState>) => {
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.status = action.payload.status;
      },
    );
  },
});

export { getAdminUsers, getDetailAdminUser, createAdminUser, updateAdminUser };
export const { clearDetailAdminUser, clearState } = adminUserSlice.actions;
export default adminUserSlice.reducer;
