/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSdk } from '../../libs/apis';
import { IImportMembersState } from './types';
import { MappedData } from '../../configs';

export const importMembers = createAsyncThunk(
  '/admin/members/import',
  async (members: MappedData[], { rejectWithValue }) => {
    try {
      const data: IImportMembersState =
        await apiSdk.memberApis.importMembers(members);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
