/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { IAdminRole, translate } from '../../libs/utils';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MAIN_LIGHT_COLOR, WHITE } from '../../themes/colors';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux';
import Button from '../../components/Button';
import { useForm } from 'react-hook-form';
import FormProvider from '../../components/FormProvider';
import { useNavigate, useParams } from 'react-router-dom';
import {
  adminUserSelector,
  errorsSelector,
} from '../../redux/admin-users/selectors';
import {
  IAdminUser,
  IDetailAdminUserState,
  IError,
} from '../../redux/admin-users/types';
import CreatePopup from '../../components/popup/CreatePopup';
import ErrorMessage from '../../components/ErrorMessage';
import { TOPIC_LABEL } from '../../themes/fonts';
import EditPopup from '../../components/popup/EditPopup';
import {
  getDetailAdminUser,
  clearState,
  clearDetailAdminUser,
} from '../../redux/admin-users';
import { ICreateAdminUser } from '../../libs/apis/admin-users/types';
import { AdminUserRole } from '../../configs';
import {
  createAdminUser,
  updateAdminUser,
} from '../../redux/admin-users/action';

const useStyles = makeStyles({
  container: {
    maxWidth: '960px',
    marginLeft: '32px',
    marginRight: '32px',
    height: '92px',
  },

  labelContainer: {
    minWidth: '240px',
    backgroundColor: MAIN_LIGHT_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },

  textLabel: {
    color: WHITE,
    fontSize: `${TOPIC_LABEL} !important`,
    fontWeight: 'bold !important',
  },

  selectContainer: {
    width: '160px',
    marginLeft: '40px',
  },

  customSelectInput: {
    borderRadius: '12px !important',
    height: '50px !important',
  },
});

const defaultValue: ICreateAdminUser = {
  name: '',
  email: '',
  password: '',
  dental_registration_number: '',
  birth_date: '',
  role: -1,
};

const adminRole: IAdminRole[] = [
  {
    value: AdminUserRole.ADMINISTRATOR,
    type: translate('administrator.create.admin_role.ADMINISTRATOR'),
  },
  {
    value: AdminUserRole.USER,
    type: translate('administrator.create.admin_role.USER'),
  },
  {
    value: AdminUserRole.OFFICER,
    type: translate('administrator.create.admin_role.OFFICER'),
  },
];

const CreateAdminScreen: React.FC = () => {
  const { id } = useParams();

  const styles = useStyles();
  const navigate = useNavigate();
  const [dataAdd, setDataAdd] = useState<ICreateAdminUser>(defaultValue);
  const [detailAdminUser, setDetailAdminUser] = useState<IAdminUser>();
  const [errorsMessage, setErrorsMessage] = useState<IError | undefined>({});
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const methods = useForm();
  const { handleSubmit } = methods;

  const errors: IError | undefined = useSelector(errorsSelector);
  const adminUser: IDetailAdminUserState = useSelector(adminUserSelector);

  const onSubmit = handleSubmit(async () => {
    const formattedDate = dataAdd.birth_date.replace(/\//g, '');
    let response: any;
    if (id) {
      response = await dispatch(
        updateAdminUser({
          adminUserData: { ...dataAdd, birth_date: formattedDate },
          id,
        }),
      );
    } else {
      response = await dispatch(
        createAdminUser({ ...dataAdd, birth_date: formattedDate }),
      );
    }

    if (response.payload.success == true) {
      if (id) {
        setOpenEditModal(true);
      } else {
        setOpenCreateModal(true);
      }
      dispatch(clearState());
    }
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    setDataAdd({
      ...dataAdd,
      [key]: e.target.value,
    });
    const messages = { ...errorsMessage };
    if (key == 'name' && messages?.name) delete messages.name;
    if (key == 'email' && messages?.email) delete messages.email;
    if (key == 'password' && messages?.password) delete messages.password;
    if (
      key == 'dental_registration_number' &&
      messages?.dental_registration_number
    )
      delete messages.dental_registration_number;
    if (key == 'birth_date') {
      if (messages?.birth_date) delete messages.birth_date;

      let value = e.target.value.replace(/\D/g, '');
      if (value.length > 4) {
        value = value.slice(0, 4) + '/' + value.slice(4);
      }
      if (value.length > 7) {
        value = value.slice(0, 7) + '/' + value.slice(7);
      }

      setDataAdd({
        ...dataAdd,
        [key]: value,
      });
    }
    setErrorsMessage(messages);
  };

  const handleSelectRole = (event: SelectChangeEvent) => {
    const messages = { ...errorsMessage };
    if (messages?.role) {
      delete messages.role;
      setErrorsMessage(messages);
    }

    setDataAdd({
      ...dataAdd,
      role: parseInt(event.target.value),
    });
  };

  const handleBackButton = () => {
    dispatch(clearState());
    dispatch(clearDetailAdminUser());
    navigate('/role');
  };

  useEffect(() => {
    dispatch(clearState());
    if (id) dispatch(getDetailAdminUser(parseInt(id)));
  }, [dispatch, id]);

  useEffect(() => {
    setErrorsMessage(errors);
  }, [errors]);

  useEffect(() => {
    if (id && adminUser?.data?.admin_user_id) {
      setDetailAdminUser(adminUser.data);
      if (detailAdminUser) {
        const adminUserValue: ICreateAdminUser = {
          name: detailAdminUser.name,
          email: detailAdminUser.email,
          role: detailAdminUser.role,
          dental_registration_number:
            detailAdminUser.dental_registration_number,
          birth_date: detailAdminUser.birth_date.toString(),
          password: detailAdminUser.password,
        };
        setDataAdd({ ...adminUserValue });
      }
    }
  }, [adminUser, detailAdminUser, id]);

  return (
    <Layout
      title={translate(
        id ? 'administrator.edit.title' : 'administrator.create.title',
      )}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('administrator.list.table.name')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              sx={{ width: '100%' }}
              id="name"
              name="name"
              type="text"
              variant="standard"
              value={dataAdd.name}
              error={!!errorsMessage?.name ?? false}
              onChange={handleOnChange}
            />
            {errorsMessage?.name && errorsMessage.name.length > 0 && (
              <Box sx={{ marginTop: '14px' }}>
                <ErrorMessage message={errorsMessage.name[0]} />
              </Box>
            )}
          </Box>
        </Stack>
        {id ? (
          ''
        ) : (
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={3}
            className={styles.container}
          >
            <Box className={styles.labelContainer}>
              <Typography className={styles.textLabel}>
                {translate('administrator.create_edit.label.password')}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                sx={{ width: '100%' }}
                id="password"
                name="password"
                type="text"
                variant="standard"
                value={dataAdd.password}
                error={!!errorsMessage?.password ?? false}
                onChange={handleOnChange}
              />
              {errorsMessage?.password && errorsMessage.password.length > 0 && (
                <Box sx={{ marginTop: '14px' }}>
                  <ErrorMessage message={errorsMessage.password[0]} />
                </Box>
              )}
            </Box>
          </Stack>
        )}
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('administrator.list.table.email')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              sx={{ width: '100%' }}
              id="email"
              name="email"
              type="text"
              variant="standard"
              error={!!errorsMessage?.email ?? false}
              value={dataAdd.email}
              onChange={handleOnChange}
            />
            {errorsMessage?.email && errorsMessage.email.length > 0 && (
              <Box sx={{ marginTop: '14px' }}>
                <ErrorMessage message={errorsMessage.email[0]} />
              </Box>
            )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('administrator.create_edit.label.role')}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: '40px' }}>
            <FormControl
              className={styles.selectContainer}
              sx={{ marginTop: '12px' }}
            >
              <InputLabel id="adminUser-role-label">
                {translate('administrator.create_edit.label.select')}
              </InputLabel>
              <Select
                labelId="adminUser-role"
                id="adminUser-role"
                label={translate('administrator.create_edit.label.select')}
                className={styles.customSelectInput}
                onChange={handleSelectRole}
                value={dataAdd.role.toString()}
                error={!!errorsMessage?.role ?? false}
              >
                {adminRole.map((type: IAdminRole) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorsMessage?.role && errorsMessage.role.length > 0 && (
              <Box sx={{ marginTop: '14px' }}>
                <ErrorMessage message={errorsMessage.role[0]} />
              </Box>
            )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate(
                'administrator.create_edit.label.dental_registration_number',
              )}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              sx={{ width: '100%' }}
              id="dental_registration_number"
              name="dental_registration_number"
              type="text"
              variant="standard"
              value={dataAdd.dental_registration_number}
              error={!!errorsMessage?.dental_registration_number ?? false}
              onChange={handleOnChange}
            />
            {errorsMessage?.dental_registration_number &&
              errorsMessage.dental_registration_number.length > 0 && (
                <Box sx={{ marginTop: '14px' }}>
                  <ErrorMessage
                    message={errorsMessage.dental_registration_number[0]}
                  />
                </Box>
              )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={3}
          className={styles.container}
        >
          <Box className={styles.labelContainer}>
            <Typography className={styles.textLabel}>
              {translate('administrator.create_edit.label.date_of_birth')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              sx={{ width: '100%' }}
              id="birth_date"
              name="birth_date"
              type="text"
              variant="standard"
              value={dataAdd.birth_date.toString()}
              error={!!errorsMessage?.birth_date ?? false}
              onChange={handleOnChange}
              inputProps={{ maxLength: 10 }}
            />
            {errorsMessage?.birth_date &&
              errorsMessage.birth_date.length > 0 && (
                <Box sx={{ marginTop: '14px' }}>
                  <ErrorMessage message={errorsMessage.birth_date[0]} />
                </Box>
              )}
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="center"
          gap={5}
          sx={{ marginY: '40px' }}
        >
          <Button
            type="submit"
            label={translate(
              id ? 'button.edit' : 'administrator.create.button.create',
            )}
            sx={{
              width: '100px',
              height: '38px',
              borderRadius: 0,
              boxShadow: 0,
            }}
            textSx={{ fontSize: '14px', fontWeight: 'bold' }}
          />
          <Button
            label={translate('administrator.create_edit.button.back')}
            onClick={() => handleBackButton()}
            sx={{
              width: '100px',
              height: '38px',
              borderRadius: 0,
              boxShadow: 0,
            }}
            textSx={{ fontSize: '14px', fontWeight: 'bold' }}
          />
        </Stack>
      </FormProvider>
      <CreatePopup
        open={openCreateModal}
        onConfirm={() => navigate('/role')}
        onClose={() => navigate('/role')}
      />
      <EditPopup
        open={openEditModal}
        onConfirm={() => navigate('/role')}
        onClose={() => navigate('/role')}
      />
    </Layout>
  );
};

export default CreateAdminScreen;
