import { RootState } from '..';
import { IData, IDetailAdminUserState, IError } from './types';

export const adminUsersSelector = (state: RootState): IData =>
  state.adminUser.data;

export const adminUserSelector = (state: RootState): IDetailAdminUserState =>
  state.adminUser.detailAdminUser;

export const errorsSelector = (state: RootState): IError =>
  state.adminUser.errors;
