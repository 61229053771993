import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';
import { API_BASE_URL, DOWNLOAD_APK_API } from '../../configs';
import logo from '../../assets/images/logo.svg';

const ApkDownloadPage: React.FC = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
        <img
          src={logo}
          alt="App Logo"
          style={{ width: '100px', marginBottom: '16px' }}
        />
        <Typography variant="h5" gutterBottom>
          アプリをダウンロードしてインストール
        </Typography>
        <a
          href={`${API_BASE_URL}${DOWNLOAD_APK_API}`}
          download
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 20px',
            backgroundColor: '#1976d2',
            color: 'white',
            textDecoration: 'none',
            borderRadius: '4px',
            fontSize: '16px',
            fontWeight: 'bold',
            boxShadow: '0px 2px 2px rgba(0,0,0,0.2)',
            marginBottom: '15px',
          }}
        >
          <CloudDownload style={{ marginRight: '8px' }} /> APKをダウンロード
        </a>
        <Typography variant="body1" gutterBottom>
          アプリをインストールするには、「不明なソースからのアプリのインストールを許可」を有効にする必要があります。
        </Typography>
        <Typography variant="body1">
          次の手順で設定してください: 設定 {'>'} セキュリティ {'>'}{' '}
          不明なソースからのアプリのインストールを有効にする。
        </Typography>
        <Typography variant="body1">
          ダウンロードしたファイルの拡張子が app.apk.zip
          の場合、ファイル名を変更し、.zip を削除して app.apk
          にした後、アプリをインストールしてください。
        </Typography>
      </Paper>
    </Container>
  );
};

export default ApkDownloadPage;
