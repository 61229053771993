/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useCallback, useEffect, useState } from 'react';
import ConfirmButton from '../ConfirmButton';
import { translate } from '../../../libs/utils';
import { ORANGE } from '../../../themes/colors';
import { IImportMembersErrors } from '../../../redux/import-members/types';
import { errorsSelector } from '../../../redux/import-members/selectors';
import { useSelector } from 'react-redux';
import LayoutImportError from './LayoutImportError';
import { Box } from '@mui/material';
import { useAppDispatch } from '../../../redux';
import { clearState } from '../../../redux/import-members';

interface Props {
  onClose: () => void;
  open: boolean;
}

const ImportErrorPopup: FC<Props> = ({ onClose, open }: Props) => {
  const [errorsMessage, setErrorsMessage] = useState<string[]>([]);
  const importMembersErrors: IImportMembersErrors = useSelector(errorsSelector);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(clearState());
    onClose();
  };

  const parseErrors = useCallback((): Set<string> => {
    const parsedErrors: string[] = [];
    for (const key in importMembersErrors) {
      const match = key.match(/^users\.(\d+)\.(\w+)$/);
      if (match) {
        const messages = importMembersErrors[key];
        messages.forEach((m: string) => parsedErrors.push(m));
      }
    }
    return new Set(parsedErrors);
  }, [importMembersErrors]);

  useEffect(() => {
    const messages = parseErrors();
    return () => {
      setErrorsMessage(Array.from(messages));
    };
  }, [parseErrors]);

  return (
    <LayoutImportError
      title={translate('popup.error.title')}
      contents={errorsMessage}
      isOpen={open}
      onClose={handleClose}
    >
      <Box sx={{ marginTop: '30px' }}>
        <ConfirmButton
          label={translate('popup.error.button')}
          width={'110px'}
          bgColor={ORANGE}
          onClick={handleClose}
        />
      </Box>
    </LayoutImportError>
  );
};

export default ImportErrorPopup;
