import { TableCell, TableRow } from '@mui/material';
import {
  MAIN_COLOR,
  MAIN_LIGHT_COLOR,
  SECOND_LIGHT_GRAY,
} from '../../themes/colors';
import { TABLE_CELL } from '../../themes/fonts';
import { makeStyles } from '@mui/styles';
import { getDate } from '../../libs/utils/formatDate';
import { IAdminUser } from '../../redux/admin-users/types';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles({
  tableCellText: {
    color: `${SECOND_LIGHT_GRAY} !important`,
    fontSize: `${TABLE_CELL} !important`,
  },

  icon: {
    color: MAIN_COLOR,
    '&:hover': {
      color: MAIN_LIGHT_COLOR,
      cursor: 'pointer',
    },
  },
});

interface Props {
  data: IAdminUser[];
  onClickEdit: (adminId: number) => void;
}

const TableBody = ({ data, onClickEdit }: Props) => {
  const styles = useStyles();

  return (
    <>
      {data?.map((row, rowIndex) => (
        <TableRow key={rowIndex}>
          <TableCell
            align="center"
            key="dental_registration_number"
            className={styles.tableCellText}
          >
            {row['dental_registration_number']}
          </TableCell>
          <TableCell align="center" key="name" className={styles.tableCellText}>
            {row['name']}
          </TableCell>
          <TableCell
            align="center"
            key="email"
            className={styles.tableCellText}
          >
            {row['email']}
          </TableCell>
          <TableCell
            align="center"
            key="birth_date"
            className={styles.tableCellText}
          >
            {getDate(row['birth_date'].toString())}
          </TableCell>
          <TableCell
            align="center"
            key="edit_action"
            className={styles.tableCellText}
          >
            <EditIcon
              className={styles.icon}
              onClick={() => onClickEdit(row.admin_user_id)}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
export default TableBody;
