import { FC, ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import { MAIN_LIGHT_COLOR, POPUP_CONTENT, WHITE } from '../../../themes/colors';
import Title from '../Title';
import Content from '../Content';
import { translate } from '../../../libs/utils';
import { API_BASE_URL, SAMPLE_MEMBER_CSV_FILE } from '../../../configs';

interface Props {
  title: string;
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles({
  container: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '430px',
    height: '220px',
    backgroundColor: WHITE,
    borderWidth: '0px',
    borderRadius: '10px',
    boxShadow: '15px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 1,
  },
});

const Layout: FC<Props> = ({ title, isOpen, onClose, children }: Props) => {
  const styles = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box className={styles.container}>
          <Stack direction={'column'} gap={2}>
            <Title title={title} />
            <Content
              content={'メールと歯科医師会番号は一意でなければなりません。'}
            />
            <Content content={'生年月日の形式は「20241231」です。'} />
            <Content
              content={
                '会員種別は「正会員」、「準会員」、「事務員」でなければなりません。'
              }
            />
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '18px',
                height: '15px',
                ml: '20px',
                color: POPUP_CONTENT,
              }}
            >
              {translate('popup.importCSV.select_file.sample_file.label')}
              <a
                href={`${API_BASE_URL}${SAMPLE_MEMBER_CSV_FILE}`}
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: MAIN_LIGHT_COLOR,
                  marginLeft: '5px',
                }}
              >
                {translate('popup.importCSV.select_file.sample_file_url.label')}
              </a>
            </Typography>
            <Box className={styles.buttonContainer}>{children}</Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default Layout;
