import { RootState } from '..';
import { IImportMembersErrors } from './types';

export const errorsSelector = (state: RootState): IImportMembersErrors =>
  state.importMembers.errors;

export const successSelector = (state: RootState): boolean =>
  state.importMembers.success;

export const isImportedSelector = (state: RootState): boolean =>
  state.importMembers.isImported;
